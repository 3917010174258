/*
 * CB Glyph
 */

@use 'apricot-variable' as *;
@use '../mixins/font' as *;
@use '../mixins/glyph' as *;
@use '../mixins/breakpoint' as *;

@use 'glyph-icons' as *;
@use 'glyph-content' as *;
@use 'glyph-content-multi' as *;

@include font-face('CB Glyphs', cb-glyphs, null, normal, woff ttf);
@include font-face('CB Glyphs Multi', cb-glyphs-multi, null, normal, woff ttf);
